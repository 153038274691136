














































































































































































































































































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
const { v4: uuidv4 } = require('uuid');

import BaseComponent from "@/components/base-component.vue";
import Steps from "@/components/steps/steps.vue";
import PaymentOptions from "@/components/payment-options/payment-options.vue";
import ProductList from "@/components/product-list/product-list.vue";
import Loader from "@/components/loader.vue";
import Dropdown from '@/components/dropdown/dropdown.vue';
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconDelete from "@/components/icons/icon-delete.vue";
import IconCheckCircleS from "@/components/icons/icon-check-circle-s.vue";
import IconIncorrect from "@/components/icons/icon-incorrect.vue";
import IconCheckBold from "@/components/icons/icon-check-bold.vue";
import IconEdit from "@/components/icons/icon-edit.vue";
import IconEmail from "@/components/icons/icon-email.vue";
import { CartState } from "@/store/modules/cart";
import { OrdersState } from "@/store/modules/orders";
import { InvoicesState } from "@/store/modules/invoices";
import { SettingsState } from "@/store/modules/settings";
import { ProductTypes } from "@/product-types";
import { PromocodeErrors } from "@/promocode-errors";
import { PaymentMethods } from "@/payment-methods";
import { SBP } from "@/SBP";
import Common from "@/plugins/common";

@Component({
  name: "Order",
  components: {
    Steps,
    PaymentOptions,
    ProductList,
    Loader,
    Dropdown,
    BaseIcon,
    IconDelete,
    IconCheckCircleS,
    IconIncorrect,
    IconCheckBold,
    IconEdit,
    IconEmail,
  },
})
export default class Order extends BaseComponent {

  private readonly cartState: CartState = getModule(CartState, this.$store);
  private readonly ordersState: OrdersState = getModule(OrdersState, this.$store);
  private readonly invoicesState: InvoicesState = getModule(InvoicesState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  email: string = "";
  errorEmail: boolean = false;
  validated: boolean = false;
  emailReadonly: boolean = false;

  paymentMethods: any[] = [];
  methodsDescription: any[] = PaymentMethods;
  selectedMethod: string = "";
  selectedPaymentType: string = "";

  promocode: string = "";
  promocodeError: string = "";

  productTypes: any = ProductTypes;

  reloadCount: number = 0;
  max_cart_reload_count: number = 30;
  max_order_reload_count: number = 100;

  orderId: string = "";
  paymentId: string = "";

  loading: boolean = true;
  isReloading: boolean = false;

  steps: any[] = [
    {
      label: "steps/order",
      state: "active",
    },
    {
      label: "steps/payment",
    },
  ];

  get theme(): any {
    return this.settingsState.theme;
  }

  get tenantName(): any {
    return this.settingsState.tenantName;
  }

  get isMySportUSA(): boolean {
    return this.settingsState.isMySportUSA === true;
  }

  get backPage(): string {
    return this.$store.state.goBack;
  }

  get cart(): any {
    return this.cartState.cart;
  }

  get cartItems(): any {
    return this.cartState.cartItems;
  }

  get amount(): number {
    return this.cartState.amount;
  }

  get totalAmount(): number {
    return this.cartState.totalAmount;
  }

  get cartItemsCount(): number {
    const allPhotos: any[] = this.getCartItems(this.productTypes.photos_with_me);
    const singlePhotos: any[] = this.getCartItems(this.productTypes.photo);
    const frames: any[] = this.getCartItems(this.productTypes.frame);
    const photosWithFrame: any[] = this.getCartItems(this.productTypes.photosWithFrame);
    let promocodes: any[] = this.getCartItems(this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    return allPhotos.length + singlePhotos.length + frames.length + photosWithFrame.length + promocodes.length;
  }

  get cartListItems(): any[] {
    const items: any[] = [];
    let promocodes: any[] = this.getCartItems(this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    for (let i = 0; i < this.cartItems.length; i += 1) {
      if (!this.cartItems[i].product) continue;
      if (this.cartItems[i].product.productType !== this.productTypes.promocode) {
        items.push({ ...this.cartItems[i] });
        continue;
      }
      const foundIndex: number = promocodes.findIndex((item: any) => item.eventId === this.cartItems[i].catalog.catalogId );
      if (foundIndex >= 0) {
        const newItem: any = { ...this.cartItems[i] };
        newItem.originalPrice = promocodes[foundIndex].originalPrice;
        newItem.totalPrice = promocodes[foundIndex].totalPrice;
        newItem.promocodesCount = promocodes[foundIndex].items ? promocodes[foundIndex].items.length || 0 : 0;
        items.push(newItem);
        promocodes[foundIndex] = {};
      }
    }
    return items;
  }

  get promocodes(): any[] {
    return this.cartState.promotionalCodes;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get isAuthenticated() {
    return this.settingsState.isAuthenticated;
  }

  get tokenParsed() {
    return this.settingsState.tokenParsed;
  }

  get displayDiscountDetails(): boolean {
    return this.theme === '' && !this.isMySportUSA && this.lang === 'ru';
  }

  formatAmount(amount: number, onlyInteger: boolean = true): string {
    return Common.formatAmount(amount, onlyInteger);
  }

  roundToPrecision(value: number, precision: number = 2): number {
    if (precision < 0) return value;
    if (precision === 0) return Math.round(value);
    return parseFloat((Math.round(value * Math.pow(10, precision + 1)) * Math.pow(10, -(precision + 1))).toFixed(precision))
  }

  getDiscount(): number {
    if (!this.cart) return 0;
    const discount: number = this.amount - this.totalAmount;
    const paymentDiscount: number = this.getCartQuickPaymentDiscount();

    return this.roundToPrecision(discount + paymentDiscount);
  }

  getCartQuickPaymentDiscount() {
    let paymentDiscount: number = 0;
    if (this.selectedPaymentType === 'QuickPayment' && SBP.enabled === true) {
      const index = this.cartState.cart.paymentTypes.findIndex((r: any) => r.paymentTypeId == "QuickPayment");
      if (index >= 0) {
        const firstDiscount = this.cartState.cart.paymentTypes[index].discounts[0];
        if (firstDiscount) {
          paymentDiscount = this.totalAmount * firstDiscount.ratio;
          if (paymentDiscount > firstDiscount.amountLimit) paymentDiscount = firstDiscount.amountLimit;
        }
      }
    }
    return this.roundToPrecision(paymentDiscount);
  }

  getDiscountDetailsText(): string {
    const {
      discountPackage,
      discountPromocode,
      discountSBP,
    } = this.getDiscountFractions();

    const detailsText: string[] = [];
    if (discountPackage > 0) {
      detailsText.push(`${this.$t('cart/discountDetailsPackage')} — ${this.formatAmount(discountPackage, false)}`);
    }
    if (discountPromocode > 0) {
      detailsText.push(`${this.$t('cart/discountDetailsPromocode')} — ${this.formatAmount(discountPromocode, false)}`);
    }
    if (SBP.enabled === true && discountSBP > 0) {
      detailsText.push(`${this.$t('cart/discountDetailsSBP')} — ${this.formatAmount(discountSBP, false)}`);
    }
    return detailsText.join('<br>');
  }

  getDiscountFractions() {
    const fractions = {
      discountPackage: 0,
      discountPromocode: 0,
      discountSBP: 0,
    }

    const items: any[] = this.cartState.cartItems;
    for (let i = 0; i < items.length; i += 1) {
      const item: any = items[i];
      let discountPromocode: number = 0;
      let discountPackage: number = 0;
      if (item.promotionalCode) {
        const discount = item.promotionalCode.discount;
        if (discount.unit === 'Percentage') {
          discountPromocode = this.roundToPrecision(item.originalPrice * ((discount.value || 0) / 100));
        }
        if (discount.unit == 'Fixed') {
          discountPromocode = this.roundToPrecision(discount.value);
        }
      }
      if (discountPromocode === 0 && (item.totalPrice < item.originalPrice)) {
        discountPackage = this.roundToPrecision(item.originalPrice - item.totalPrice);
      }
      if (discountPromocode > 0 && (item.price < item.originalPrice)) {
        discountPackage = this.roundToPrecision((item.originalPrice - discountPromocode) - item.totalPrice);
      }
      fractions.discountPackage = this.roundToPrecision(fractions.discountPackage + discountPackage);
      fractions.discountPromocode = this.roundToPrecision(fractions.discountPromocode + discountPromocode);
    }

    fractions.discountSBP = this.getCartQuickPaymentDiscount();

    return fractions;
  }

  getTotalAmount() {
    if (this.selectedPaymentType === 'QuickPayment' && SBP.enabled === true) {
      return this.totalAmount - this.getCartQuickPaymentDiscount();
    }
    return this.totalAmount;
  }

  resetErrorState(): void {
    this.errorEmail = false;
  }

  setSelectedMethod(index: number): void {
    if (this.isReloading) return;

    if (index < 0 || index > this.paymentMethods.length-1) {
      return;
    }

    this.selectedMethod = this.paymentMethods[index].id;
    this.selectedPaymentType = this.paymentMethods[index].typeId;
  }

  hasDiscount(): boolean {
    return this.getDiscount() > 0;
  }

  getCartItems(type: string): any[] {
    const products: any[] = Object.assign([], this.cartState.cartItems);
    if (products.length === 0) {
      return [];
    } else {      
      const found: any[] = products.filter((r: any) => r.product ? r.product.productType === type : false);
      return found;
    }
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
          originalPrice: items[item].originalPrice,
          totalPrice: items[item].totalPrice,
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
          grouped[index].originalPrice += items[item].originalPrice;
          grouped[index].totalPrice += items[item].totalPrice;
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
            originalPrice: items[item].originalPrice,
            totalPrice: items[item].totalPrice,
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }

  @Watch("email")
  async onEmailChanged(): Promise<void> {
    if (this.loading) return;

    this.resetErrorState();
  }

  @Watch("promocode")
  async onPromocodeChanged(): Promise<void> {
    this.promocodeError = "";
  }

  @Watch("backPage")
  async goBack(page: string): Promise<void> {
    if (!page) return;

    this.$router.push(page);
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    await this.updatePaymentTypes();
  }

  @Watch("loading")
  async onShow(): Promise<void> {
    setTimeout(() => { this.settingsState.setLoadingRoute(this.loading) }, 1000);
  }

  async onGoToCart(): Promise<void> {
    await this.$router.push({ name: "cart" });
  }

  async onApplyPromocode(): Promise<void> {
    if (this.promocode.length == 0) return;

    this.settingsState.reachGoal('use_promocode');

    await this.cartState.addPromocode({ cartId: this.cartState.cartId, code: this.promocode });
    if (this.cartState.isCartError) {
      if (this.cartState.cartErrorData !== null && this.cartState.cartErrorData.code !== undefined) {
        
        const code: string = this.cartState.cartErrorData.code;
        if (code == PromocodeErrors.notFound) {
          this.promocodeError = this.$t('promocode/errorNotFound').toString();
          return;
        } else if (code == PromocodeErrors.expired) {
          this.promocodeError = this.$t('promocode/errorExpired').toString();
          return;
        } else if (code == PromocodeErrors.inactive) {
          this.promocodeError = this.$t('promocode/errorInactive').toString();
          return;
        } else if (code == PromocodeErrors.conflict) {
          this.promocodeError = this.$t('promocode/errorConflict').toString();
          return;
        } else if (code == PromocodeErrors.limit) {
          this.promocodeError = this.$t('promocode/errorLimit').toString();
          return;
        } else if (code == PromocodeErrors.restricted) {
          this.promocodeError = this.$t('promocode/errorRestricted').toString();
          return;
        } else {
          this.promocodeError = this.$t('promocode/errorUnknown').toString() + ": " + code;
          return;
        }
      } else {
        this.promocodeError = this.$t('promocode/errorUnknown').toString();
        return;
      }
      
    }
    
    await this.updatePaymentTypes();

    this.promocodeError = "";
    this.promocode = "";
    
    this.reloadCount = 0;
    this.reloadCart();
  }

  async onRemovePromocode(codeId: string): Promise<void> {
    await this.cartState.removePromocode({ cartId: this.cartState.cartId, codeId: codeId });
    await this.updatePaymentTypes();
    this.reloadCount = 0;
    this.reloadCart();
  }

  async onSubmit(event: Event): Promise<void> {
    event.preventDefault();
  }

  async reloadCart(): Promise<void> {
    this.isReloading = true;
    await this.cartState.getCart({ cartId: this.cartState.cartId });

    if (this.cartState.isCartError) {
      await this.updatePaymentTypes();
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    if (this.cartState.isUpdated) {
      await this.updatePaymentTypes();
      this.isReloading = false;
      this.reloadCount = 0;
      return;
    }

    this.reloadCount++;
    if (this.reloadCount < this.max_cart_reload_count) {
      if (this.$route.name == "order") {
        setTimeout(async () => this.reloadCart(), 1000);
      }
    } else {
      await this.updatePaymentTypes();
      this.isReloading = false;
      this.reloadCount = 0;
    }
  }

  async reloadOrderReady(): Promise<void> {
    this.isReloading = true;
    await this.ordersState.getOrder({ orderId: this.orderId });
    
    if (this.ordersState.isReadyForPayment) {
      this.isReloading = true;
      this.reloadCount = 0;
      this.onReadyForPayment();
      return;
    } else if (this.ordersState.isCompleted) {
      this.isReloading = true;
      this.reloadCount = 0;
      await this.$router.push({ name: "order-paid", params: { id: this.orderId } });
      return;
    }

    this.reloadCount++;
    if (this.reloadCount < this.max_order_reload_count) {
      if (this.$route.name == "order") {
        setTimeout(async () => this.reloadOrderReady(), 1000);
      }
    } else {
      this.isReloading = false;
      this.reloadCount = 0;
    }
  }

  async onPay(): Promise<void> {
    if (this.isReloading) return;

    this.resetErrorState();
    this.validated = false;

    if (this.orderId.length == 0) {
      await this.cartState.setCustomer({ cartId: this.cartState.cartId, customer: { email: this.email } });

      if (this.cartState.isCustomerError) {
        this.errorEmail = true;
        this.validated = false;
        this.backToTop();
        return;
      }
      
      this.validated = true;
      
      await this.cartState.checkout({ cartId: this.cartState.cartId });
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      this.orderId = this.cartState.orderId;
      if (this.orderId.length == 0) {
        return;
      } else {
        await this.cartState.removeCartIds();
      }
    }
    this.validated = true;

    this.reloadCount = 0;
    this.reloadOrderReady();
  }

  async onReadyForPayment(): Promise<void> {
    this.isReloading = true;

    const invoiceId: string = this.ordersState.invoiceId;
    if (invoiceId.length == 0) {
      this.isReloading = false;
      return;
    }

    const payload: any = {
      invoiceId: invoiceId,
      payment: {
        paymentId: this.paymentId,
        paymentTypeId: this.selectedPaymentType,
        redirectUrl: window.location.origin + "/order/" + this.orderId,
      },
    };
    await this.invoicesState.createPayment(payload);

    if (this.invoicesState.responseStatus !== 200) {
      if (this.selectedMethod === "Internal") {
        await this.ordersState.getOrder({ orderId: this.orderId });

        if (this.ordersState.isCompleted) {
          await this.$router.push({ name: "order-paid", params: { id: this.orderId } });
          return;
        }
      }
      if (this.invoicesState.responseStatus === 409) {
        this.paymentId = uuidv4();
      }
      this.isReloading = false;
      return;
    }

    const url: string = this.invoicesState.payUrl;
    if (url.length == 0) {
      this.isReloading = false;
      return;
    }

    this.settingsState.reachGoal("media_pay");
    await this.ordersState.addPendingOrder(this.orderId);

    await this.cartState.removeCart();
    
    window.location.href = url;

    this.isReloading = false;
  }

  async updatePaymentTypes(): Promise<void> {
    await this.invoicesState.getPaymentTypes({ 
      amount: this.cartState.totalAmount, 
      currencyId: this.cartState.currencyId,
      albumId: this.getAlbumIds(),
    });

    const paymentTypes: any[] = this.invoicesState.paymentTypes;
    const types: any[] = [];

    const methodsDescription = this.methodsDescription.filter((r: any) => {
      const isEnabledForTenant = !r.enabledFor || r.enabledFor.indexOf(this.tenantName) !== -1;
      const isDisabledForTenant = r.disabledFor && r.disabledFor.indexOf(this.tenantName) !== -1;
      return isEnabledForTenant && !isDisabledForTenant;
    });

    if (paymentTypes.length > 0) {
      for (let i = 0; i < paymentTypes.length; i++) {
        const found: any[] = methodsDescription.filter((r: any) => r.typeId == paymentTypes[i].paymentTypeId);

        if (found.length == 0) {
          const item: any = {
            id: paymentTypes[i].paymentTypeId,
            typeId: paymentTypes[i].paymentTypeId,
            priority: paymentTypes[i].priority,
            title: paymentTypes[i].title,
            description: '',
            discountBadge: '',
          }
          types.push(item);
        } else {
          const items = found.map((type) => ({
            id: type.id,
            typeId: type.typeId,
            priority: paymentTypes[i].priority,
            title: this.$t(type.title),
            description: this.$t(type.description),
            discountBadge: (this.theme === '' && SBP.enabled === true && type.discountBadge) || '',
          }));
          types.push(...items);
        }
      }
    }

    if (types.length > 0) {
      let index: number = types.findIndex((r: any) => r.id == 'QuickPayment');
      if (this.theme === '' && this.tenantName !== 'Sporttiming' && !this.isMySportUSA && index >= 0) {
        types[index]['desktop'] = '/img/sbp/short-sbp.svg';
        types[index]['mobile'] = '/img/sbp/short-sbp.svg';
      }

      const found: any[] = types.filter((r: any) => r.id == 'Internal');
      if (found.length != 0) {
        this.paymentMethods = [];
        this.selectedMethod = 'Internal';
        this.selectedPaymentType = 'Internal';
        return;
      }

      this.paymentMethods = types.sort(this.sortByPriority);

      this.selectedMethod = this.paymentMethods[0].id;
      this.selectedPaymentType = this.paymentMethods[0].typeId;
    }
  }

  getAlbumIds(): string[] {
    const albumId: string[] = [];
    const items: any[] = this.cartState.cartItems;
    for (let i = 0; i < items.length; i += 1) {
      const id: string = items[i].catalog?.catalogId || "";
      if (id && !albumId.includes(id)) albumId.push(id);
    }
    return albumId;
  }

  sortByPriority(a: any, b: any): number {
    if (a.priority < b.priority) return 1;
    if (a.priority > b.priority) return -1;
    return 0;
  }

  async checkAuthenticated() {
    if (this.isAuthenticated && this.tokenParsed) {
      if (this.tokenParsed.email) this.email = this.tokenParsed.email;
      this.emailReadonly = true;
    }
  }

  async mounted(): Promise<void> {
    this.$store.state.buttonHome = false;
    this.$store.state.buttonBack = true;
    this.$store.state.buttonCart = true;
    this.$store.state.showMenu = false;

    await this.checkAuthenticated();

    await this.settingsState.setLoadingRoute(true);

    await this.cartState.loadCartId();

    if (this.cartState.cartId != "") {
      
      await this.cartState.getCart({ cartId: this.cartState.cartId });
      
      if (this.cartState.isCartError) {
        await this.cartState.removeCart();
      } else if (this.cartState.isCheckoutDone) {
        await this.ordersState.getOrder({ orderId: this.cartState.orderId });
        if (this.ordersState.isCanceled) {
          await this.cartState.removeCart();
        }
      }
    }

    if (this.cartState.cartId != "") {
      
      await this.updatePaymentTypes();

      if (this.cartState.isCheckoutDone) {
        
        if (this.ordersState.isCompleted) {
          await this.$router.push({ name: "order-paid" });
          return;
        } else {
          this.orderId = this.cartState.orderId;
          if (this.cartState.customer !== undefined && 
            this.cartState.customer.email !== undefined) {
              this.email = this.cartState.customer.email;
              this.validated = true;
          }
          this.$store.state.buttonBack = false;
        }

      } else {
        
        if (this.cartState.customer !== undefined && 
            this.cartState.customer.email !== undefined) {
          this.email = this.cartState.customer.email;
          this.validated = true;
        } else {
          this.$store.state.history = ["cart"];
        }

      }
    } else {
      this.$store.state.history = [];
      await this.$router.push({ name: "home" });
      return;
    }

    this.paymentId =  uuidv4();
    
    this.$store.state.goBack = "";
    this.backToTop();

    this.loading = false;

    this.settingsState.trackPage("order");
  }

}
